@font-face {
font-family: '__VontobelSans_c2d9a1';
src: url(/_next/static/media/c3efbb5eacd5fb2f-s.p.woff) format('woff');
font-display: swap;
font-weight: 1 1000;
font-style: normal;
}

@font-face {
font-family: '__VontobelSans_c2d9a1';
src: url(/_next/static/media/299fd65727b7da09-s.p.woff) format('woff');
font-display: swap;
font-weight: 1 1000;
font-style: italic;
}@font-face {font-family: '__VontobelSans_Fallback_c2d9a1';src: local("Arial");ascent-override: 89.98%;descent-override: 22.85%;line-gap-override: 18.80%;size-adjust: 106.36%
}.__className_c2d9a1 {font-family: '__VontobelSans_c2d9a1', '__VontobelSans_Fallback_c2d9a1'
}.__variable_c2d9a1 {--font-vontobel-sans: '__VontobelSans_c2d9a1', '__VontobelSans_Fallback_c2d9a1'
}

@font-face {
font-family: '__VontobelSerif_646a81';
src: url(/_next/static/media/57ed295664fd6e69-s.p.woff) format('woff');
font-display: swap;
font-weight: 1 1000;
font-style: normal;
}

@font-face {
font-family: '__VontobelSerif_646a81';
src: url(/_next/static/media/a13058f303b4d3e1-s.p.woff) format('woff');
font-display: swap;
font-weight: 1 1000;
font-style: italic;
}@font-face {font-family: '__VontobelSerif_Fallback_646a81';src: local("Arial");ascent-override: 94.53%;descent-override: 24.00%;line-gap-override: 19.76%;size-adjust: 101.23%
}.__className_646a81 {font-family: '__VontobelSerif_646a81', '__VontobelSerif_Fallback_646a81'
}.__variable_646a81 {--font-vontobel-serif: '__VontobelSerif_646a81', '__VontobelSerif_Fallback_646a81'
}

